import { Flex } from '@echodex/uikit'
import { AppBody } from 'components/App'
import { useRouter } from 'next/router'
import { useContext, useEffect, useMemo, useState } from 'react'

import { useDefaultsFromURLSearch } from 'state/swap/hooks'
import { SmartRouter } from '@echodex/smart-router/evm'
import { AppBodyDetail } from 'components/App/AppBody'
import Page from '../Page'
import { V3SwapForm } from './V3Swap'
import { StyledInputCurrencyWrapper, StyledSwapContainer } from './styles'
import { SwapFeaturesContext } from './SwapFeaturesContext'
import { useSwapBestTrade } from './V3Swap/hooks'
import { TradeDetails } from './V3Swap/containers'

export default function Swap() {
    const { query } = useRouter()
    const { isChartExpanded, isChartDisplayed, setIsChartDisplayed } = useContext(SwapFeaturesContext)
    const [firstTime, setFirstTime] = useState(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const toggleChartDisplayed = () => {
        setIsChartDisplayed((currentIsChartDisplayed) => !currentIsChartDisplayed)
    }

    useEffect(() => {
        if (firstTime && query.showTradingReward) {
            setFirstTime(false)

            if (isChartDisplayed) {
                toggleChartDisplayed()
            }
        }
    }, [firstTime, isChartDisplayed, query, toggleChartDisplayed])

    useDefaultsFromURLSearch()

    const { isLoading, trade, refresh, syncing, isStale, error } = useSwapBestTrade()
    const tradeLoaded = !isLoading
    const price = useMemo(() => trade && SmartRouter.getExecutionPrice(trade), [trade])

    return (
        <Page removePadding={isChartExpanded} hideFooterOnDesktop={isChartExpanded}>
            <Flex
                width={['328px', '100%']}
                height="100%"
                justifyContent="center"
                position="relative"
                alignItems="flex-start"
            >
                <StyledSwapContainer $isChartExpanded={isChartExpanded}>
                    <StyledInputCurrencyWrapper mt={isChartExpanded ? '24px' : '0'}>
                        <AppBody>
                            <V3SwapForm
                                trade={trade}
                                refresh={refresh}
                                tradeLoaded={tradeLoaded}
                                syncing={syncing}
                                isStale={isStale}
                                error={error}
                            />
                        </AppBody>
                        <AppBodyDetail style={{ marginLeft: tradeLoaded ? 0 : -410, borderWidth: tradeLoaded ? '1px' : 0 }}>
                            <TradeDetails loaded={tradeLoaded} trade={trade} price={price} />
                        </AppBodyDetail>
                    </StyledInputCurrencyWrapper>
                </StyledSwapContainer>
            </Flex>
        </Page>
    )
}
