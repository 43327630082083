import { useTranslation } from "@echodex/localization";
import { useIsMounted } from "@echodex/hooks";
import { PropsWithChildren, ReactNode } from "react";
import { AutoColumn, RowBetween, Text, TextProps, IconButton, PencilIcon } from "../../components";

type SwapInfoType = {
  price: ReactNode;
  allowedSlippage?: number;
  onSlippageClick?: () => void;
  allowedSlippageSlot?: React.ReactNode;
};

export const SwapInfoLabel = (props: PropsWithChildren<TextProps>) => (
  <Text fontSize="16px" color="textSubtle" {...props} />
);

export const SwapInfo = ({ allowedSlippage, price, onSlippageClick, allowedSlippageSlot }: SwapInfoType) => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();

  return (
    <AutoColumn gap="sm" style={{ padding: "0 24px" }}>
      <RowBetween alignItems="center">{price}</RowBetween>
      {typeof allowedSlippage === "number" && (
        <RowBetween alignItems="center" style={{paddingTop: "5px"}}>
          <SwapInfoLabel>
            {t("Slippage Tolerance")}
            {onSlippageClick ? (
              <IconButton scale="sm" variant="text" onClick={onSlippageClick}>
                <PencilIcon color="primary" width="10px" />
              </IconButton>
            ) : null}
          </SwapInfoLabel>
          {isMounted && (allowedSlippageSlot ?? <Text>{allowedSlippage / 100}%</Text>)}
        </RowBetween>
      )}
    </AutoColumn>
  );
};
