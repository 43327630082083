import { TradeType } from '@echodex/sdk'
import { SmartRouterTrade } from '@echodex/smart-router/evm'
import { AutoColumn } from '@echodex/uikit'
import { useMemo, memo } from 'react'

import { TradeSummary } from 'views/Swap/components/AdvancedSwapDetails'
import { AdvancedDetailsFooter } from 'views/Swap/components/AdvancedSwapDetailsDropdown'
import { Currency, Price } from '@echodex/swap-sdk-core'
import { RoutingSettingsButton } from 'components/Menu/GlobalSettings/SettingsModal'
import { RouteDisplay } from '../components'
import { useSlippageAdjustedAmounts, useIsWrapping } from '../hooks'
import { computeTradePriceBreakdown } from '../utils/exchange'
import { PricingAndSlippage } from './PricingAndSlippage'


interface Props {
    loaded: boolean
    trade?: SmartRouterTrade<TradeType> | null
    price?: Price<Currency, Currency>
}

export const TradeDetails = memo(function TradeDetails({ loaded, trade, price }: Props) {
    const slippageAdjustedAmounts = useSlippageAdjustedAmounts(trade)
    const isWrapping = useIsWrapping()
    const { priceImpactWithoutFee, lpFeeAmount } = useMemo(() => computeTradePriceBreakdown(trade), [trade])

    if (isWrapping || !loaded || !trade) {
        return null
    }

    const { inputAmount, outputAmount, tradeType, routes } = trade

    return (
        <AdvancedDetailsFooter show={loaded}>
            <AutoColumn gap="0px">
                <AutoColumn gap="48px" style={{padding: "24px"}}>
                    {routes.map((route, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <RouteDisplay key={i} route={route} />
                    ))}
                    <RoutingSettingsButton />
                </AutoColumn>
                <TradeSummary
                    slippageAdjustedAmounts={slippageAdjustedAmounts}
                    inputAmount={inputAmount}
                    outputAmount={outputAmount}
                    tradeType={tradeType}
                    priceImpactWithoutFee={priceImpactWithoutFee}
                    realizedLPFee={lpFeeAmount}
                    hasStablePair={false}
                />
                <PricingAndSlippage priceLoading={!loaded} price={price} />
            </AutoColumn>
        </AdvancedDetailsFooter>
    )
})
