import { Modal } from '@echodex/uikit'
import { useTranslation } from '@echodex/localization'

const ConfirmSwapModalContainer = ({ children, handleDismiss }) => {
    const { t } = useTranslation()

    return (
        <Modal title={t('Confirm Swap')} headerBackground="gradientCardHeader" onDismiss={handleDismiss}>
            {children}
        </Modal>
    )
}

export default ConfirmSwapModalContainer
