import { Box, Flex } from '@echodex/uikit'
import styled from 'styled-components'
import { maxMediaQueries } from 'utils/theme'

export const StyledSwapContainer = styled(Flex)<{ $isChartExpanded: boolean }>`
    flex-direction: row;
    flex-shrink: 0;
    height: fit-content;
    padding: 0 16px;
    ${({ theme }) => theme.mediaQueries.lg} {
        padding: 0 40px;
    }
    ${maxMediaQueries.sm} {
        width: 100%

    }
    ${({ theme }) => theme.mediaQueries.xxl} {
        ${({ $isChartExpanded }) => ($isChartExpanded ? 'padding: 0 120px' : 'padding: 0 40px')};
    }
`

export const StyledInputCurrencyWrapper = styled(Box)`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    ${maxMediaQueries.lg} {
        flex-direction: column;
        gap: 12px;
    }
`
