import throttle from 'lodash/throttle'
import { useMemo } from 'react'
import { SmartRouterTrade } from '@echodex/smart-router/evm'
import { TradeType } from '@echodex/sdk'
import { FormHeader, FormMain, SwapCommitButton } from './containers'

type Props = {
    tradeLoaded: boolean
    syncing: boolean
    isStale: boolean
    refresh: () => void
    trade: SmartRouterTrade<TradeType> | undefined
    error: Error | undefined
}

export function V3SwapForm({ tradeLoaded, syncing, isStale, refresh, trade, error }: Props) {
    const throttledHandleRefresh = useMemo(
        () =>
            throttle(() => {
                refresh()
            }, 3000),
        [refresh],
    )

    return (
        <>
            <FormHeader onRefresh={throttledHandleRefresh} refreshDisabled={!tradeLoaded || syncing || !isStale} />
            <FormMain
                tradeLoading={!tradeLoaded}
                inputAmount={trade?.inputAmount}
                outputAmount={trade?.outputAmount}
                swapCommitButton={<SwapCommitButton trade={trade} tradeError={error} tradeLoading={!tradeLoaded} />}
            />
        </>
    )
}
