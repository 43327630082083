import { useTranslation } from '@echodex/localization'
import {
    ChartDisableIcon,
    ChartIcon,
    Flex,
    HistoryIcon,
    HotDisableIcon,
    HotIcon,
    IconButton,
    NotificationDot,
    Swap,
    Text,
    TooltipText,
    useModal,
    useTooltip,
} from '@echodex/uikit'
import RefreshIcon from 'components/Svg/RefreshIcon'
import { CHAIN_REFRESH_TIME } from 'config/constants/exchange'
import { useExpertMode } from '@echodex/utils/user'
import TransactionsModal from 'components/App/Transactions/TransactionsModal'
import GlobalSettings from 'components/Menu/GlobalSettings'
import { useSwapHotTokenDisplay } from 'hooks/useSwapHotTokenDisplay'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { useRoutingSettingChanged } from 'state/user/smartRouter'
import { useAtom } from 'jotai'
import { ReactElement, useCallback, useContext, useEffect, useState, memo } from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import atomWithStorageWithErrorCatch from 'utils/atomWithStorageWithErrorCatch'
import InternalLink from 'components/Links'
import Image from 'next/image'
import { SettingsMode } from '../../../components/Menu/GlobalSettings/types'
import { SwapFeaturesContext } from '../SwapFeaturesContext'
import BuyCryptoIcon from '../../../../public/images/moneyBangs.svg'

interface Props {
    title: string | ReactElement
    noConfig?: boolean
    setIsChartDisplayed?: React.Dispatch<React.SetStateAction<boolean>>
    isChartDisplayed?: boolean
    hasAmount: boolean
    onRefreshPrice: () => void
}

const SUPPORTED_BUY_CRYPTO_CHAINS = [1, 56]

const ColoredIconButton = styled(IconButton)`
    color: ${({ theme }) => theme.colors.textSubtle};
    overflow: hidden;
`

//  disable this during the v3 campaign
const mobileShowOnceTokenHighlightAtom = atomWithStorageWithErrorCatch('echodex::mobileShowOnceTokenHighlightV2', true)

const CurrencyInputHeader: React.FC<React.PropsWithChildren<Props>> = memo(({ title, hasAmount, onRefreshPrice }) => {
    const { t } = useTranslation()
    const { chainId } = useActiveChainId()
    const [mobileTooltipShowOnce, setMobileTooltipShowOnce] = useAtom(mobileShowOnceTokenHighlightAtom)
    const [mobileTooltipShow, setMobileTooltipShow] = useState(false)

    const { tooltip, tooltipVisible, targetRef } = useTooltip(<Text>{t('Check out the top traded tokens')}</Text>, {
        placement: isMobile ? 'top' : 'bottom',
        trigger: isMobile ? 'focus' : 'hover',
        ...(isMobile && { manualVisible: mobileTooltipShow }),
    })
    const {
        tooltip: buyCryptoTooltip,
        tooltipVisible: buyCryptoTooltipVisible,
        targetRef: buyCryptoTargetRef,
    } = useTooltip(<Text>{t('Buy crypto with fiat.')}</Text>, {
        placement: isMobile ? 'top' : 'bottom',
        trigger: isMobile ? 'focus' : 'hover',
        ...(isMobile && { manualVisible: mobileTooltipShow }),
    })

    const { isChartSupported, isChartDisplayed, setIsChartDisplayed, isHotTokenSupported } =
        useContext(SwapFeaturesContext)
    const [expertMode] = useExpertMode()
    const [isRoutingSettingChange] = useRoutingSettingChanged()
    const toggleChartDisplayed = () => {
        setIsChartDisplayed((currentIsChartDisplayed) => !currentIsChartDisplayed)
    }
    const [onPresentTransactionsModal] = useModal(<TransactionsModal />)
    const [isSwapHotTokenDisplay, setIsSwapHotTokenDisplay] = useSwapHotTokenDisplay()

    const mobileTooltipClickOutside = useCallback(() => {
        setMobileTooltipShow(false)
    }, [])

    useEffect(() => {
        if (isMobile && !mobileTooltipShowOnce) {
            setMobileTooltipShow(true)
            setMobileTooltipShowOnce(true)
        }
    }, [mobileTooltipShowOnce, setMobileTooltipShowOnce])

    useEffect(() => {
        document.body.addEventListener('click', mobileTooltipClickOutside)
        return () => {
            document.body.removeEventListener('click', mobileTooltipClickOutside)
        }
    }, [mobileTooltipClickOutside])

    const titleContent = (
        <Flex width="100%" alignItems="center" justifyContent="space-between" flexDirection="row">
            <Flex flexDirection="column" alignItems="flex-start" width="100%" marginBottom={15}>
                <Swap.CurrencyInputHeaderTitle>{title}</Swap.CurrencyInputHeaderTitle>
            </Flex>
            <Flex width="100%" justifyContent="end">
                {SUPPORTED_BUY_CRYPTO_CHAINS.includes(chainId) ? (
                    <Flex alignItems="center" justifyContent="center" px="4px" mt="5px">
                        <TooltipText
                            ref={buyCryptoTargetRef}
                            onClick={() => setMobileTooltipShow(false)}
                            display="flex"
                            style={{ justifyContent: 'center' }}
                        >
                            <InternalLink href="/buy-crypto">
                                <Image src={BuyCryptoIcon} alt="#" style={{ justifyContent: 'center' }} />
                            </InternalLink>
                        </TooltipText>
                        {buyCryptoTooltipVisible && (!isMobile || mobileTooltipShow) && buyCryptoTooltip}
                    </Flex>
                ) : null}
                {isChartSupported && setIsChartDisplayed && (
                    <ColoredIconButton
                        onClick={() => {
                            if (!isChartDisplayed && isSwapHotTokenDisplay) {
                                setIsSwapHotTokenDisplay(false)
                            }
                            toggleChartDisplayed()
                        }}
                        variant="text"
                        scale="sm"
                    >
                        {isChartDisplayed ? (
                            <ChartDisableIcon color="textSubtle" />
                        ) : (
                            <ChartIcon width="24px" color="textSubtle" />
                        )}
                    </ColoredIconButton>
                )}
                {isHotTokenSupported && (
                    <ColoredIconButton
                        variant="text"
                        scale="sm"
                        onClick={() => {
                            if (!isSwapHotTokenDisplay && isChartDisplayed) {
                                toggleChartDisplayed()
                            }
                            setIsSwapHotTokenDisplay(!isSwapHotTokenDisplay)
                        }}
                    >
                        {isSwapHotTokenDisplay ? (
                            <HotDisableIcon color="textSubtle" width="24px" />
                        ) : (
                            <>
                                <TooltipText
                                    ref={targetRef}
                                    onClick={() => setMobileTooltipShow(false)}
                                    display="flex"
                                    style={{ justifyContent: 'center' }}
                                >
                                    <HotIcon color="textSubtle" width="24px" />
                                </TooltipText>
                                {tooltipVisible && (!isMobile || mobileTooltipShow) && tooltip}
                            </>
                        )}
                    </ColoredIconButton>
                )}
                <NotificationDot show={expertMode || isRoutingSettingChange}>
                    <GlobalSettings color="primary" mr="0" mode={SettingsMode.SWAP_LIQUIDITY} />
                </NotificationDot>
                <IconButton onClick={onPresentTransactionsModal} variant="text" scale="sm">
                    <HistoryIcon color="primary" width="24px" />
                </IconButton>
                <IconButton variant="text" scale="sm" onClick={onRefreshPrice}>
                    <RefreshIcon
                        disabled={!hasAmount}
                        color="primary"
                        width="27px"
                        duration={CHAIN_REFRESH_TIME[chainId] ? CHAIN_REFRESH_TIME[chainId] / 1000 : undefined}
                    />
                </IconButton>
            </Flex>
        </Flex>
    )

    return <Swap.CurrencyInputHeader title={titleContent} subtitle={<></>} />
})

export default CurrencyInputHeader
