import { useTranslation } from '@echodex/localization'
import { FC, useCallback } from 'react'

import CurrencyInputHeader from '../../components/CurrencyInputHeader'

export const FormHeader: FC<{ refreshDisabled: boolean; onRefresh: () => void }> = ({ refreshDisabled, onRefresh }) => {
    const { t } = useTranslation()

    const handleRefresh = useCallback(() => {
        if (refreshDisabled) {
            return
        }
        onRefresh()
    }, [onRefresh, refreshDisabled])

    return <CurrencyInputHeader title={t('Swap')} hasAmount={!refreshDisabled} onRefreshPrice={handleRefresh} />
}
