import { Swap as SwapUI, useModal } from '@echodex/uikit'
import { useTranslation } from '@echodex/localization'
import { Price, Currency } from '@echodex/sdk'
import { useUserSlippage } from '@echodex/utils/user'
import { memo } from 'react'

import SettingsModal from '../../../../components/Menu/GlobalSettings/SettingsModal'
import { SettingsMode } from '../../../../components/Menu/GlobalSettings/types'
import { useIsWrapping } from '../hooks'

interface Props {
    showSlippage?: boolean
    priceLoading?: boolean
    price?: Price<Currency, Currency>
}

export const PricingAndSlippage = memo(function PricingAndSlippage({
    priceLoading,
    price,
    showSlippage = true,
}: Props) {
    const { t } = useTranslation()
    const [allowedSlippage] = useUserSlippage()
    const isWrapping = useIsWrapping()
    const [onPresentSettingsModal] = useModal(<SettingsModal mode={SettingsMode.SWAP_LIQUIDITY} />)

    if (isWrapping) {
        return null
    }

    const priceNode = price ? (
        <>
            <SwapUI.InfoLabel>{t('Price')}</SwapUI.InfoLabel>
            <SwapUI.TradePrice price={price} loading={priceLoading} />
        </>
    ) : null

    return (
        <SwapUI.Info
            price={priceNode}
            allowedSlippage={showSlippage ? allowedSlippage : undefined}
            onSlippageClick={onPresentSettingsModal}
        />
    )
})
