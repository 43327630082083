import { useTranslation } from '@echodex/localization'
import { Currency, CurrencyAmount, Percent, TradeType } from '@echodex/sdk'
import { LegacyPair as Pair } from '@echodex/smart-router/legacy-router'
import { Modal, ModalV2, QuestionHelper, SearchIcon, Text, Flex, AutoColumn } from '@echodex/uikit'
import { formatAmount } from '@echodex/utils/formatFractions'
import { useState, memo } from 'react'
import { RowBetween, RowFixed } from 'components/Layout/Row'
import { RoutingSettingsButton } from 'components/Menu/GlobalSettings/SettingsModal'
import { Field } from 'state/swap/actions'
import FormattedPriceImpact from './FormattedPriceImpact'
import { RouterViewer } from './RouterViewer'
import SwapRoute from './SwapRoute'

export const TradeSummary = memo(function TradeSummary({
    inputAmount,
    outputAmount,
    tradeType,
    slippageAdjustedAmounts,
    priceImpactWithoutFee,
    realizedLPFee,
}: {
    hasStablePair?: boolean
    inputAmount?: CurrencyAmount<Currency>
    outputAmount?: CurrencyAmount<Currency>
    tradeType?: TradeType
    slippageAdjustedAmounts: {
        INPUT?: CurrencyAmount<Currency>
        OUTPUT?: CurrencyAmount<Currency>
    }
    priceImpactWithoutFee?: Percent
    realizedLPFee?: CurrencyAmount<Currency>
}) {
    const { t } = useTranslation()
    const isExactIn = tradeType === TradeType.EXACT_INPUT

    return (
        <AutoColumn style={{ padding: '15px 24px' }}>
            <RowBetween>
                <RowFixed>
                    <Text fontSize="16px" color="textSubtle">
                        {isExactIn ? t('Minimum received') : t('Maximum sold')}
                    </Text>
                    <QuestionHelper
                        text={t(
                            'Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.',
                        )}
                        ml="4px"
                        placement="top"
                    />
                </RowFixed>
                <RowFixed>
                    <Text fontSize="16px">
                        {isExactIn
                            ? `${formatAmount(slippageAdjustedAmounts[Field.OUTPUT], 4)} ${
                                  outputAmount.currency.symbol
                              }` ?? '-'
                            : `${formatAmount(slippageAdjustedAmounts[Field.INPUT], 4)} ${
                                  inputAmount.currency.symbol
                              }` ?? '-'}
                    </Text>
                </RowFixed>
            </RowBetween>
            {priceImpactWithoutFee && (
                <RowBetween style={{ padding: '20px 0 0 0' }}>
                    <RowFixed>
                        <Text fontSize="16px" color="textSubtle">
                            {t('Price Impact')}
                        </Text>
                        <QuestionHelper
                            text={
                                <>
                                    <Text>
                                        <Text bold display="inline-block">
                                            {t('AMM')}
                                        </Text>
                                        {`: ${t(
                                            'The difference between the market price and estimated price due to trade size.',
                                        )}`}
                                    </Text>
                                    <Text mt="10px">
                                        <Text bold display="inline-block">
                                            {t('MM')}
                                        </Text>
                                        {`: ${t('No slippage against quote from market maker')}`}
                                    </Text>
                                </>
                            }
                            ml="4px"
                            placement="top"
                        />
                    </RowFixed>

                    <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
                </RowBetween>
            )}

            {realizedLPFee && (
                <RowBetween style={{ padding: '20px 0 0 0' }}>
                    <RowFixed>
                        <Text fontSize="16px" color="textSubtle">
                            {t('Trading Fee')}
                        </Text>
                        <QuestionHelper
                            text={
                                <>
                                    <Text mb="12px">
                                        <Text bold display="inline-block">
                                            {t('AMM')}
                                        </Text>
                                        :{' '}
                                        {t(
                                            'Fee ranging from 0.1% to 0.01% depending on the pool fee tier. You can check the fee tier by clicking the magnifier icon under the “Route” section.',
                                        )}
                                    </Text>
                                </>
                            }
                            ml="4px"
                            placement="top"
                        />
                    </RowFixed>
                    <Text fontSize="16px">{`${formatAmount(realizedLPFee, 4)} ${inputAmount.currency.symbol}`}</Text>
                </RowBetween>
            )}
        </AutoColumn>
    )
})

export interface AdvancedSwapDetailsProps {
    hasStablePair?: boolean
    pairs?: Pair[]
    path?: Currency[]
    priceImpactWithoutFee?: Percent
    realizedLPFee?: CurrencyAmount<Currency>
    slippageAdjustedAmounts?: {
        INPUT?: CurrencyAmount<Currency>
        OUTPUT?: CurrencyAmount<Currency>
    }
    inputAmount?: CurrencyAmount<Currency>
    outputAmount?: CurrencyAmount<Currency>
    tradeType?: TradeType
    isMM?: boolean
}

export const AdvancedSwapDetails = memo(function AdvancedSwapDetails({
    pairs,
    path,
    priceImpactWithoutFee,
    realizedLPFee,
    slippageAdjustedAmounts,
    inputAmount,
    outputAmount,
    tradeType,
    hasStablePair,
}: AdvancedSwapDetailsProps) {
    const { t } = useTranslation()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const showRoute = Boolean(path && path.length > 1)
    return (
        <AutoColumn gap="0px">
            {inputAmount && (
                <>
                    <TradeSummary
                        inputAmount={inputAmount}
                        outputAmount={outputAmount}
                        tradeType={tradeType}
                        slippageAdjustedAmounts={slippageAdjustedAmounts}
                        priceImpactWithoutFee={priceImpactWithoutFee}
                        realizedLPFee={realizedLPFee}
                        hasStablePair={hasStablePair}
                    />
                    {showRoute && (
                        <>
                            <RowBetween style={{ padding: '0 24px' }}>
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <Text fontSize="14px" color="textSubtle">
                                        {t('Route')}
                                    </Text>
                                    <QuestionHelper
                                        text={t(
                                            'Routing through these tokens resulted in the best price for your trade.',
                                        )}
                                        ml="4px"
                                        placement="top"
                                    />
                                </span>
                                <SwapRoute path={path} />
                                <SearchIcon style={{ cursor: 'pointer' }} onClick={() => setIsModalOpen(true)} />
                                <ModalV2
                                    closeOnOverlayClick
                                    isOpen={isModalOpen}
                                    onDismiss={() => setIsModalOpen(false)}
                                >
                                    <Modal
                                        title={
                                            <Flex justifyContent="center">
                                                {t('Route')}{' '}
                                                <QuestionHelper
                                                    text={t(
                                                        'Route is automatically calculated based on your routing preference to achieve the best price for your trade.',
                                                    )}
                                                    ml="4px"
                                                    placement="top"
                                                />
                                            </Flex>
                                        }
                                        onDismiss={() => setIsModalOpen(false)}
                                    >
                                        <RouterViewer
                                            inputCurrency={inputAmount.currency}
                                            pairs={pairs}
                                            path={path}
                                            outputCurrency={outputAmount.currency}
                                        />
                                        <Flex mt="3em" width="100%" justifyContent="center">
                                            <RoutingSettingsButton />
                                        </Flex>
                                    </Modal>
                                </ModalV2>
                            </RowBetween>
                        </>
                    )}
                </>
            )}
        </AutoColumn>
    )
})
