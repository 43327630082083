import { Token } from '@echodex/sdk'
import { Modal, Box, InjectedModalProps } from '@echodex/uikit'
import ImportToken from 'components/SearchModal/ImportToken'
import { useTranslation } from '@echodex/localization'

interface Props extends InjectedModalProps {
    tokens: Token[]
    onCancel: () => void
}

const ImportTokenWarningModal: React.FC<React.PropsWithChildren<Props>> = ({ tokens, onDismiss, onCancel }) => {
    const { t } = useTranslation()
    return (
        <Modal
            title={t('Import Token')}
            onDismiss={() => {
                onDismiss?.()
                onCancel()
            }}
        >
            <Box maxWidth="380px">
                <ImportToken tokens={tokens} handleCurrencySelect={onDismiss} />
            </Box>
        </Modal>
    )
}

export default ImportTokenWarningModal
